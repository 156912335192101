import {jwtDecode} from 'jwt-decode';
import React, {FC, Suspense, lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {AgentsLayout} from '../admin-components/src/layout/AgentLayout';
import {AppsLayout} from '../admin-components/src/layout/AppLayout';
import {DashboardLayout, Layout} from '../components';
import {FallbackView} from '../components/FallbackView';
import {AgentLayout} from '../components/src/layout/AgentLayout';
import {AppLayout} from '../components/src/layout/AppLayout';
import {VerifyAccount} from '../pages/auth/developer/Verify';
import {DocumentCenter} from '../pages/auth/developer/Verify/step/DocumentCenter';
import {StepEight} from '../pages/auth/developer/Verify/step/StepEight';
import {StepEleven} from '../pages/auth/developer/Verify/step/StepEleven';
import {StepFive} from '../pages/auth/developer/Verify/step/StepFive';
import {StepFour} from '../pages/auth/developer/Verify/step/StepFour';
import {StepNine} from '../pages/auth/developer/Verify/step/StepNine';
import {StepSeven} from '../pages/auth/developer/Verify/step/StepSeven';
import {StepSix} from '../pages/auth/developer/Verify/step/StepSix';
import {StepTen} from '../pages/auth/developer/Verify/step/StepTen';
import {StepThirteen} from '../pages/auth/developer/Verify/step/StepThirteen';
import {StepThree} from '../pages/auth/developer/Verify/step/StepThree';
import {StepTwelve} from '../pages/auth/developer/Verify/step/StepTwelve';
import {StepTwo} from '../pages/auth/developer/Verify/step/StepTwo';
import {DeveloperPortal} from '../pages/developer/DeveloperPortal';
import NotFound from '../pages/error/NotFoud';

interface ProtectedRouteProps {
  children: React.ReactNode;
  roles?: string[];
}

const AppRouter: FC = () => {
  const AdminDashboard = lazy(() => import('../pages/admin'));
  const AccountManager = lazy(() => import('../pages/admin/AccountManager'));
  const CreateUser = lazy(() => import('../pages/admin/CreateUser/Index'));
  const AddExchangeRate = lazy(
    () => import('../pages/admin/Finance/ExchangeRate/AddExchangeRate')
  );

  const AdBankExchangeRate = lazy(
    () => import('../pages/admin/Finance/ExchangeRate/AddBankExchangeRate')
  );

  const BankExchangeFund = lazy(
    () => import('../pages/admin/Finance/BankExchangeFund/index')
  );

  const AddbankExchangeFund = lazy(
    () => import('../pages/admin/Finance/BankExchangeFund/Add')
  );

  const ExchangeRate = lazy(
    () => import('../pages/admin/Finance/ExchangeRate/ExchangeRate')
  );
  const AddFeeBand = lazy(
    () => import('../pages/admin/Finance/FeeBand/AddFeeBand')
  );

  const AddFeeBandBank = lazy(
    () => import('../pages/admin/Finance/FeeBand/AddFeeBandBank')
  );
  const FeeBand = lazy(() => import('../pages/admin/Finance/FeeBand/FeeBand'));
  const BankFund = lazy(
    () => import('../pages/admin/Finance/BankFund/BankFund')
  );
  const BankFund2 = lazy(
    () => import('../pages/admin/Finance/BankFund2/BankFund')
  );
  const Funds = lazy(() => import('../pages/admin/Finance/Funds/Funds'));
  const AddBank = lazy(
    () => import('../pages/admin/Finance/Prefund-banks/AddBank')
  );
  const PrefundBanks = lazy(
    () => import('../pages/admin/Finance/Prefund-banks/PrefundBanks')
  );
  const Transactions = lazy(
    () => import('../pages/admin/Finance/Transactions/Transactions')
  );

  const TransactionDetail = lazy(
    () => import('../pages/admin/Finance/Transactions/Detail/TransactionDetail')
  );

  const Banks = lazy(() => import('../pages/admin/DataSteward/Banks/Banks'));

  const UploadBanks = lazy(
    () => import('../pages/admin/DataSteward/Banks/UploadBank')
  );

  const Country = lazy(
    () => import('../pages/admin/DataSteward/Country/Country')
  );

  const Add_Country = lazy(
    () => import('../pages/admin/DataSteward/Country/AddCountry')
  );
  const Add_Currency = lazy(
    () => import('../pages/admin/DataSteward/Currency/AddCurrency')
  );
  const Add_Currency_Pair = lazy(
    () => import('../pages/admin/DataSteward/CurrencyPair/AddCurrencyPair')
  );
  const Add_Entities = lazy(
    () => import('../pages/admin/DataSteward/Entities/AddEntities')
  );

  const Currency = lazy(
    () => import('../pages/admin/DataSteward/Currency/Index')
  );
  const CurrencyPair = lazy(
    () => import('../pages/admin/DataSteward/CurrencyPair/Index')
  );

  const Entities = lazy(
    () => import('../pages/admin/DataSteward/Entities/Index')
  );

  const EntitiesResponse = lazy(
    () => import('../pages/admin/DataSteward/EntitiesResponse/Index')
  );

  const EntitiesResponseAdd = lazy(
    () =>
      import('../pages/admin/DataSteward/EntitiesResponse/AddEntitiesResponse')
  );
  const OnboardingRules = lazy(
    () => import('../pages/admin/DataSteward/OnboardingRules/Index')
  );

  const OnboardingRulesAdd = lazy(
    () =>
      import('../pages/admin/DataSteward/OnboardingRules/AddOnboardingRules')
  );

  const SecretQuestions = lazy(
    () => import('../pages/admin/DataSteward/SecretQuestions/Index')
  );

  const SecretQuestionsAdd = lazy(
    () => import('../pages/admin/DataSteward/SecretQuestions/SecretQuestions')
  );

  const Add_Bank = lazy(
    () => import('../pages/admin/DataSteward/Banks/AddBank')
  );

  const Thresholds = lazy(
    () => import('../pages/admin/Finance/Thresholds/Thresholds')
  );
  const AddPreFund = lazy(() => import('../pages/admin/PreFund/AddPreFund'));
  const AddPreFundBank = lazy(
    () => import('../pages/admin/PreFund/AddPreFundBank')
  );
  const PreFund = lazy(() => import('../pages/admin/PreFund/Index'));
  const ForgotPassword = lazy(
    () => import('../pages/auth/admin/forgot-password/ForgotPassword')
  );
  const ResetPassword = lazy(
    () => import('../pages/auth/admin/forgot-password/ResetPassword')
  );
  const Signin = lazy(() => import('../pages/auth/admin/new-login/Signin'));

  const ForgetPassword = lazy(
    () => import('../pages/auth/developer/forget-password')
  );
  const AccountDetails = lazy(
    () => import('../pages/admin/AccountDetails/Index')
  );

  const QuestionairePreview = lazy(
    () => import('../pages/admin/AccountDetails/QuestionairePreview')
  );
  const AdminOtpVerification = lazy(
    () => import('../pages/auth/admin/forgot-password/OtpVerification')
  );
  const DeveloperPortalLogin = lazy(
    () => import('../pages/auth/developer/login/DeveloperPortalLogin')
  );
  const NewPassword = lazy(
    () => import('../pages/auth/developer/new-password')
  );
  const OtpVerification = lazy(
    () => import('../pages/auth/developer/pinSection/OtpVerification')
  );
  const OtpVerificationLogin = lazy(
    () => import('../pages/auth/developer/pinSection/OtpVerificationLogin')
  );
  const TransactionPin = lazy(
    () => import('../pages/auth/developer/pinSection/TransactionPin')
  );
  const Register = lazy(() => import('../pages/auth/developer/register'));

  const KnowYou = lazy(
    () => import('../pages/auth/developer/know-you/KnowYou')
  );

  const VerifyRegister = lazy(
    () => import('../pages/auth/developer/verify-register')
  );
  const Prefund = lazy(() => import('../pages/developer/PreFund/Index'));
  // const Transactions = lazy(() => import("../pages/developer/Transaction"));
  const Dashboard = lazy(() => import('../pages/web'));
  const FAQ = lazy(() => import('../pages/web/Faq'));
  const Terms = lazy(() => import('../pages/web/Terms'));
  const Admin = lazy(() => import('../pages/web/admin/Admin'));
  const Developer = lazy(() => import('../pages/web/developer'));
  const Privacy = lazy(() => import('../pages/web/privacy'));
  const DeveloperPrefundBanks = lazy(
    () => import('../pages/developer/PrefundBanks/PrefundBanks')
  );
  const DeveloperAddPrefund = lazy(
    () => import('../pages/developer/PreFund/AddPrefund')
  );
  const DeveloperFunds = lazy(() => import('../pages/developer/Funds/Funds'));
  const DeveloperProfile = lazy(
    () => import('../pages/developer/Settings/Settings')
  );
  const DeveloperOrder = lazy(() => import('../pages/developer/Order/Order'));
  const DeveloperAddOrder = lazy(
    () => import('../pages/developer/Order/AddOrder/Sender')
  );
  const DeveloperAddReceiverOrder = lazy(
    () => import('../pages/developer/Order/AddOrder/Receiver')
  );

  const DeveloperOrderDetails = lazy(
    () => import('../pages/developer/Order/Details/OrderDetails')
  );
  const DeveloperAddAmountOrder = lazy(
    () => import('../pages/developer/Order/AddOrder/Amount')
  );
  // KYC STEPS
  const StepOne = lazy(
    () => import('../pages/auth/developer/Verify/step/StepOne')
  );

  const isAuthenticated = (): boolean => {
    // Check if token exists in localStorage or sessionStorage
    const token = sessionStorage.getItem('token');
    return !!token; // Return true if token exists, otherwise false
  };

  const isAdminAuthenticated = (): boolean => {
    // Check if token exists in localStorage or sessionStorage
    const token = sessionStorage.getItem('adminToken');
    return !!token; // Return true if token exists, otherwise false
  };

  // ProtectedRoute component to wrap around routes
  const ProtectedRoute = ({children}: ProtectedRouteProps) => {
    const user = isAuthenticated();
    if (!user) {
      return <Navigate to='/developer-portal-login' replace={true} />;
    }
    return <>{children}</>;
  };

  const AdminProtectedRoutes = ({children, roles}: ProtectedRouteProps) => {
    const user = isAdminAuthenticated();

    if (!user) {
      return <Navigate to='/admin-portal/login' replace={true} />;
    }
    return <>{children}</>;
  };

  const token = sessionStorage.getItem('adminToken');
  const decoded: any = token && jwtDecode(token as string);
  console.log(decoded?.roles_rights);

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        {/* WEBSITE */}
        <Route element={<DashboardLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/home' element={<Dashboard />} />
          <Route path='/developer-portal' element={<Developer />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
        </Route>

        {/* DEVELOPER PORTAL --> VERIFY ACCOUNT */}
        <Route path='/developer-portal/verify' element={<VerifyAccount />} />

        {/* DEVELOPER PORTAL --> ONBOARDING */}
        <Route element={<Layout />}>
          {/* <Route path="/" element={<Login />} /> */}
          {/* <Route path="/" element={<DeveloperPortalLogin />} /> */}

          <Route
            path='/developer-portal-login'
            element={<DeveloperPortalLogin />}
          />
          <Route path='/signup' element={<Register />} />
          <Route path='/verify' element={<VerifyRegister />} />
          <Route path='/forgot-password' element={<ForgetPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/otpVerify' element={<OtpVerification />} />
          <Route path='/otpVerify-login' element={<OtpVerificationLogin />} />
          <Route path='/createPin' element={<TransactionPin />} />
          <Route path='/tell_us_more' element={<KnowYou />} />
        </Route>

        {/* DEVELOPER PORTAL */}
        <Route element={<AppLayout />}>
          <Route element={<AgentLayout />}>
            <Route
              path='/developer-portal/home'
              element={
                <ProtectedRoute>
                  <DeveloperPortal />
                </ProtectedRoute>
              }
            />

            <Route
              path='/developer-portal/prefund-banks'
              element={
                <ProtectedRoute>
                  <DeveloperPrefundBanks />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/prefund'
              element={
                <ProtectedRoute>
                  <Prefund />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/add-prefund'
              element={
                <ProtectedRoute>
                  <DeveloperAddPrefund />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/update-prefund'
              element={
                <ProtectedRoute>
                  <DeveloperAddPrefund />
                </ProtectedRoute>
              }
            />

            <Route
              path='/developer-portal/funds'
              element={
                <ProtectedRoute>
                  <DeveloperFunds />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/account'
              element={
                <ProtectedRoute>
                  <DeveloperProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path='/developer-portal/settings/security'
              element={
                <ProtectedRoute>
                  <DeveloperProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/settings/account'
              element={
                <ProtectedRoute>
                  <DeveloperProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/order'
              element={
                <ProtectedRoute>
                  <DeveloperOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/order/add-order/receiver'
              element={
                <ProtectedRoute>
                  <DeveloperAddReceiverOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/order/add-order/amount'
              element={
                <ProtectedRoute>
                  <DeveloperAddAmountOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/order/add-order'
              element={
                <ProtectedRoute>
                  <DeveloperAddOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path='/developer-portal/order/details/:id'
              element={
                <ProtectedRoute>
                  <DeveloperOrderDetails />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>

        {/* KYC --> QUESTIONAIRRE */}
        <Route element={<VerifyAccount />}>
          <Route
            path='/developer-portal/kyc/corporate_information'
            element={
              <ProtectedRoute>
                <StepOne />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/business_activity'
            element={
              <ProtectedRoute>
                <StepTwo />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/ownership_structure'
            element={
              <ProtectedRoute>
                <StepThree />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/corporate_governance'
            element={
              <ProtectedRoute>
                <StepFour />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/policies_practicies_and_procedures'
            element={
              <ProtectedRoute>
                <StepFive />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/risk_assessment'
            element={
              <ProtectedRoute>
                <StepSix />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/know_your_customer_and_customer_due_diligence'
            element={
              <ProtectedRoute>
                <StepSeven />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/transaction_monitoring_and_suspicious_activity_reporting'
            element={
              <ProtectedRoute>
                <StepEight />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/sanction_screening'
            element={
              <ProtectedRoute>
                <StepNine />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/record_keeping'
            element={
              <ProtectedRoute>
                <StepTen />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/internal_and_external_audit'
            element={
              <ProtectedRoute>
                <StepEleven />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/aml_training'
            element={
              <ProtectedRoute>
                <StepTwelve />
              </ProtectedRoute>
            }
          />

          <Route
            path='/developer-portal/kyc/facta_compliance'
            element={
              <ProtectedRoute>
                <StepThirteen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/developer-portal/kyc/document_center'
            element={
              <ProtectedRoute>
                <DocumentCenter />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* ADMIN PORTAL --> ONBOARDING */}
        <Route path='/admin-portal' element={<Admin />} />
        <Route path='/admin-portal/login' element={<Signin />} />
        <Route
          path='/admin-portal/forgot_password'
          element={<ForgotPassword />}
        />
        <Route
          path='/admin-portal/verify_otp'
          element={<AdminOtpVerification />}
        />
        <Route
          path='/admin-portal/reset_password'
          element={<ResetPassword />}
        />

        {/* ADMIN PORTAL */}
        <Route element={<AppsLayout />}>
          <Route element={<AgentsLayout />}>
            <Route
              path='/admin-portal/dashboard'
              element={
                <AdminProtectedRoutes>
                  <AdminDashboard />
                </AdminProtectedRoutes>
              }
            />
            <Route
              path='/admin-portal/account-manager'
              element={
                <AdminProtectedRoutes>
                  <AccountManager />
                </AdminProtectedRoutes>
              }
            />
            <Route
              path='/admin-portal/account-details'
              element={<AccountDetails />}
            />
            <Route
              path='/admin-portal/account-details/:email/questionaire_preview'
              element={<QuestionairePreview />}
            />
            <Route path='/admin-portal/create-user' element={<CreateUser />} />

            <Route path='/admin-portal/finance/prefund' element={<PreFund />} />
            <Route
              path='/admin-portal/finance/prefund/add-pre-fund'
              element={<AddPreFund />}
            />

            <Route
              path='/admin-portal/finance/prefund/add-pre-fund-bank'
              element={<AddPreFundBank />}
            />
            <Route
              path='/admin-portal/finance/thresholds'
              element={<Thresholds />}
            />
            <Route
              path='/admin-portal/finance/prefund-banks'
              element={<PrefundBanks />}
            />
            <Route
              path='/admin-portal/finance/transactions'
              element={<Transactions />}
            />
            <Route
              path='/admin-portal/finance/transactions/:id'
              element={<TransactionDetail />}
            />

            <Route
              path='/admin-portal/data-steward/banks'
              element={<Banks />}
            />
            <Route
              path='/admin-portal/data-steward/banks/add'
              element={<Add_Bank />}
            />
            <Route
              path='/admin-portal/data-steward/banks/update'
              element={<Add_Bank />}
            />

            <Route
              path='/admin-portal/data-steward/country/add'
              element={<Add_Country />}
            />

            <Route
              path='/admin-portal/data-steward/country/update'
              element={<Add_Country />}
            />

            <Route
              path='/admin-portal/data-steward/country'
              element={<Country />}
            />

            <Route
              path='/admin-portal/data-steward/currency'
              element={<Currency />}
            />

            <Route
              path='/admin-portal/data-steward/currency/add'
              element={<Add_Currency />}
            />
            <Route
              path='/admin-portal/data-steward/currency/update'
              element={<Add_Currency />}
            />
            <Route
              path='/admin-portal/data-steward/currency/pair/add'
              element={<Add_Currency_Pair />}
            />
            <Route
              path='/admin-portal/data-steward/currency/pair/update'
              element={<Add_Currency_Pair />}
            />

            <Route
              path='/admin-portal/data-steward/currency/pair'
              element={<CurrencyPair />}
            />

            <Route
              path='/admin-portal/data-steward/entities'
              element={<Entities />}
            />

            <Route
              path='/admin-portal/data-steward/entities/add'
              element={<Add_Entities />}
            />
            <Route
              path='/admin-portal/data-steward/entities/update'
              element={<Add_Entities />}
            />

            <Route
              path='/admin-portal/data-steward/entities-response'
              element={<EntitiesResponse />}
            />

            <Route
              path='/admin-portal/data-steward/entities-response/add'
              element={<EntitiesResponseAdd />}
            />

            <Route
              path='/admin-portal/data-steward/entities-response/update'
              element={<EntitiesResponseAdd />}
            />

            <Route
              path='/admin-portal/data-steward/onboarding-rules'
              element={<OnboardingRules />}
            />

            <Route
              path='/admin-portal/data-steward/onboarding-rules/add'
              element={<OnboardingRulesAdd />}
            />

            <Route
              path='/admin-portal/data-steward/onboarding-rules/update'
              element={<OnboardingRulesAdd />}
            />

            <Route
              path='/admin-portal/data-steward/secret-questions'
              element={<SecretQuestions />}
            />

            <Route
              path='/admin-portal/data-steward/secret-questions/add'
              element={<SecretQuestionsAdd />}
            />

            <Route
              path='/admin-portal/data-steward/secret-questions/update'
              element={<SecretQuestionsAdd />}
            />

            <Route
              path='/admin-portal/finance/prefund-banks/addbank'
              element={<AddBank />}
            />
            {/*<Route path='/admin-portal/finance/funds' element={<BankFund />} />*/}
            <Route path='/admin-portal/finance/fund' element={<BankFund2 />} />
            <Route path='/admin-portal/finance/feeband' element={<FeeBand />} />
            <Route
              path='/admin-portal/finance/bankfund'
              element={<BankFund />}
            />
            <Route
              path='/admin-portal/finance/add-feeband'
              element={<AddFeeBand />}
            />

            <Route
              path='/admin-portal/finance/add-feeband-bank'
              element={<AddFeeBandBank />}
            />

            <Route
              path='/admin-portal/finance/exchange-rate'
              element={<ExchangeRate />}
            />
            <Route
              path='/admin-portal/finance/exchange-rate/add'
              element={<AddExchangeRate />}
            />

            <Route
              path='/admin-portal/finance/bank-exchange-rate/add'
              element={<AdBankExchangeRate />}
            />

            <Route
              path='/admin-portal/finance/bankExchangeFund'
              element={<BankExchangeFund />}
            />

            <Route
              path='/admin-portal/finance/bankExchangeFund/add'
              element={<AddbankExchangeFund />}
            />
          </Route>
        </Route>

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export {AppRouter};
